<template>
  <div class="my-project-requests-request-documents">
    <data-loading :loading="dataLoading" :lock="true"/>
    <form @input="validateForm" @change="validateForm" @submit.stop.prevent="submitForm">
      <div class="h6">Прикрепить документы</div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.financialStatements">
              Бухгалтерская отчётность
            </file-upload>
            <input-errors :errors="errors['financialStatements']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.registrationCertificate">
              Копия свидетельства о регистрации
            </file-upload>
            <input-errors :errors="errors['registrationCertificate']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.charter">
              Устав
            </file-upload>
            <input-errors :errors="errors['charter']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.accountingCertificate">
              Копия свидетельства о постановке на налоговый учёт
            </file-upload>
            <input-errors :errors="errors['accountingCertificate']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.headAppointmentDecision">
              Решение о назначении директора
            </file-upload>
            <input-errors :errors="errors['headAppointmentDecision']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <file-upload :files="documents.additionalDocuments">
              Дополнительные документы
            </file-upload>
            <input-errors :errors="errors['additionalDocuments']"/>
          </div>
        </div>
      </div>
      <div class="h6 mt-3">Реквизиты</div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="account-number">№ счёта</label>
            <input v-model="documents.accountNumber"
                   class="form-control form-control-lg"
                   id="account-number"
                   type="text"
                   placeholder="№ счёта"
                   required>
            <input-errors :errors="errors['accountNumber']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="correspondent-account-number">Кор. счёт</label>
            <input v-model="documents.correspondentAccountNumber"
                   class="form-control form-control-lg"
                   id="correspondent-account-number"
                   type="text"
                   placeholder="Кор. счёт"
                   required>
            <input-errors :errors="errors['correspondentAccountNumber']"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="bank-name">Банк</label>
            <input v-model="documents.bankName"
                   class="form-control form-control-lg"
                   id="bank-name"
                   type="text"
                   placeholder="Банк"
                   required>
            <input-errors :errors="errors['bankName']"/>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="text-muted" for="bic">БИК</label>
            <input v-model="documents.bic"
                   class="form-control form-control-lg"
                   id="bic"
                   type="text"
                   placeholder="БИК"
                   required>
            <input-errors :errors="errors['bic']"/>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-group text-right">
        <router-link :to="{ name: 'myProjectRequests.request.collaterals', params: { uuid } }"
                     class="btn btn-lg btn-outline-info mr-3">
          Назад
        </router-link>
        <button type="submit" class="btn btn-lg btn-success">Подать заявку</button>
      </div>
    </form>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { DataLoading, FileUpload, InputErrors, Processing } from "@/components/common";
import { DocumentsManager } from "../managers/DocumentsManager";

const documentsManager = new DocumentsManager()

export default {
  name: "Documents",
  components: {
    DataLoading,
    FileUpload,
    InputErrors,
    Processing
  },
  async mounted() {
    this.dataLoading = true;
    try {
      Object.assign(this.documents, await documentsManager.getOne(this.uuid));
      this.validateForm();
    } finally {
      this.dataLoading = false;
    }
  },
  data() {
    return {
      documents: {
        financialStatements: [],
        charter: [],
        headAppointmentDecision: [],
        registrationCertificate: [],
        accountingCertificate: [],
        additionalDocuments: [],
        accountNumber: "",
        correspondentAccountNumber: "",
        bankName: "",
        bic: "",
      },
      errors: {},
      dataLoading: false,
      processing: false
    };
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    }
  },
  methods: {
    validateForm() {
      const numberRequiredFields = 10; // 9 + button
      const errors = {};
      if (this.documents.financialStatements.length === 0) {
        errors['financialStatements'] = ["Необходимо прикрепить бухгалтерскую отчётность"];
      }
      if (this.documents.charter.length === 0) {
        errors['charter'] = ["Необходимо прикрепить устав"];
      }
      if (this.documents.headAppointmentDecision.length === 0) {
        errors['headAppointmentDecision'] = ["Необходимо прикрепить решение о назначении директора"];
      }
      if (this.documents.registrationCertificate.length === 0) {
        errors['registrationCertificate'] = ["Необходимо прикрепить копию свидетельства о регистрации"];
      }
      if (this.documents.accountingCertificate.length === 0) {
        errors['accountingCertificate'] = ["Необходимо прикрепить копию свидетельства о постановке на налоговый учёт"];
      }
      if (!this.documents.accountNumber) {
        errors['accountNumber'] = ["Поле обязательное для заполнения"];
      }
      if (!this.documents.correspondentAccountNumber) {
        errors['correspondentAccountNumber'] = ["Поле обязательное для заполнения"];
      }
      if (!this.documents.bankName) {
        errors['bankName'] = ["Поле обязательное для заполнения"];
      }
      if (!this.documents.bic) {
        errors['bic'] = ["Поле обязательное для заполнения"];
      }
      this.errors = errors;
      const numberErrors = Object.keys(errors).length;
      this.$emit("progress", (numberRequiredFields - numberErrors - 1) / numberRequiredFields);
      return numberErrors === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        this.processing = true;
        try {
          await documentsManager.save(this.uuid, this.documents);
          await this.$router.push({ name: "myProjectRequests.list" });
        } finally {
          this.processing = false;
        }
      }
    }
  }
}
</script>
