import { createUploadedFileFromUrl } from "@/utils";
import { UploadedFile } from "@/utils/api-client/models/upload/UploadedFile";
import { createProjectRequestManager } from "@/utils/di";

type Documents = {
  readonly financialStatements: Array<UploadedFile>;
  readonly charter: Array<UploadedFile>;
  readonly headAppointmentDecision: Array<UploadedFile>;
  readonly registrationCertificate: Array<UploadedFile>;
  readonly accountingCertificate: Array<UploadedFile>;
  readonly additionalDocuments: Array<UploadedFile>;
  readonly accountNumber: string;
  readonly correspondentAccountNumber: string;
  readonly bankName: string;
  readonly bic: string;
}

export class DocumentsManager {
  async getOne(uuid: string): Promise<Documents> {
    const manager = await createProjectRequestManager();
    const data = await manager.getDocuments(uuid);
    return {
      financialStatements: (data.financialStatements || []).map((url) => createUploadedFileFromUrl(url)),
      charter: (data.charter || []).map((url) => createUploadedFileFromUrl(url)),
      headAppointmentDecision: (data.headAppointmentDecision || []).map((url) => createUploadedFileFromUrl(url)),
      registrationCertificate: (data.registrationCertificate || []).map((url) => createUploadedFileFromUrl(url)),
      accountingCertificate: (data.accountingCertificate || []).map((url) => createUploadedFileFromUrl(url)),
      additionalDocuments: (data.additionalDocuments || []).map((url) => createUploadedFileFromUrl(url)),
      accountNumber: data.accountNumber,
      correspondentAccountNumber: data.correspondentAccountNumber,
      bankName: data.bankName,
      bic: data.bic
    };
  }

  async save(uuid: string, documents: Documents): Promise<void> {
    const manager = await createProjectRequestManager();
    return manager.setDocuments(uuid, {
      accountNumber: documents.accountNumber,
      correspondentAccountNumber: documents.correspondentAccountNumber,
      bankName: documents.bankName,
      bic: documents.bic,
      financialStatements: documents.financialStatements.map(({url}) => url),
      charter: documents.charter.map(({url}) => url),
      headAppointmentDecision: documents.headAppointmentDecision.map(({url}) => url),
      registrationCertificate: documents.registrationCertificate.map(({url}) => url),
      accountingCertificate: documents.accountingCertificate.map(({url}) => url),
      additionalDocuments: documents.additionalDocuments.map(({url}) => url)
    });
  }
}
